<template>
    <div
        class="date-range-picker"
        :class="classNames">
        <label>{{ label }}</label>
        <date-range-picker
            ref="picker"
            :locale-data="{ firstDay: 1, format: 'MM-DD-YYYY' }"
            :show-week-numbers="true"
            :show-dropdowns="true"
            :auto-apply="true"
            :date-range="dateRange"
            :max-date="maxDate"
            :ranges="ranges"
            @update="handleUpdate">
            <template #input="picker">
                <span style="min-width: 350px;">
                    {{ picker.startDate | moment('MM-DD-YYYY') }} - {{ picker.endDate | moment('MM-DD-YYYY') }}
                </span>
            </template>
        </date-range-picker>
    </div>
</template>

<script>
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { mapState } from 'vuex';
import { SET_DATE_RANGE } from '@/store/mutation-types';

export default {
    components: { DateRangePicker },
    props: {
        label: {
            type: String,
            default: 'Select Dates:'
        },
        styled: {
            type: Boolean,
            default: false
        },
        panelPosition: {
            type: String,
            default: null,
            validate(value) {
                return ['right'].includes(value);
            }
        }
    },
    data() {
        return {
            ranges: {}
        };
    },
    computed: {
        ...mapState({
            dateRange: (state) => state.metrics.dateRange
        }),
        classNames() {
            const names = ['date-range-picker--styled'];

            if (this.panelPosition) {
                names.push('date-range-picker--' + this.panelPosition);
            }

            return names;
        },
        maxDate() {
            return moment().add(1, 'days').toDate();
        }
    },
    created() {
        this.ranges = this.getRanges();
    },
    methods: {
        handleUpdate(dateRange) {
            this.$store.commit(SET_DATE_RANGE, dateRange);
        },
        getRanges() {
            return {
                'Today': [
                    new Date(),
                    new Date()
                ],
                'Yesterday': [
                    moment().subtract(1, 'day').toDate(),
                    moment().subtract(1, 'day').toDate(),
                ],
                'Last 7 Days': [
                    moment().subtract(8, 'day').toDate(),
                    moment().subtract(1, 'day').toDate(),
                ],
                'Last 14 Days': [
                    moment().subtract(15, 'day').toDate(),
                    moment().subtract(1, 'day').toDate(),
                ],
                'Last 30 Days': [
                    moment().subtract(31, 'day').toDate(),
                    moment().subtract(1, 'day').toDate(),
                ],
                'Last 90 Days': [
                    moment().subtract(91, 'day').toDate(),
                    moment().subtract(1, 'day').toDate(),
                ],
                'This Month' : [
                    moment().startOf('month').toDate(),
                    new Date()
                ],
                'Last Month' : [
                    moment().subtract(1, 'month').startOf('month').toDate(),
                    moment().subtract(1, 'month').endOf('month').toDate()
                ]
            };
        }
    }
};
</script>

<style lang="scss">
.date-range-picker {
    margin-top: -3px;
    &.date-range-picker--right {
        .daterangepicker {
            transform: translate(0) !important;
            left: 0 !important;
            &::before {
                left: 40px;
            }
        }
    }
    &.date-range-picker--styled {
        position: relative;
        margin-top: 0;
        label {
            position: absolute;
            z-index: 10;
            right: auto;
            top: 6px;
            left: 10px;
            display: block;
            color: $gray;
            text-transform: uppercase;
            font-size: 12px;
            transform-origin: top left;
            transform: translateY(-14px) scale(0.7);
            background-color: #fff;
            color: rgba(0, 0, 0, 0.54);
            padding: 0 6px;
            height: 20px;
            line-height: 20px;
            font-size: 16px;
        }
        .reportrange-text {
            padding: 8px 10px;
            border: 1px solid $gray-light;
            color: $gray-dark;
            font-size: 14px;
            line-height: 16px;
        }
    }
    &:not(.date-range-picker--styled) {
        label {
            display: block;
            color: $gray;
            text-transform: uppercase;
            font-size: 12px;
        }
        .reportrange-text {
            padding: 9px 15px;
            border: 1px solid $gray-light;
            border-radius: 4px;
            color: $gray;
            font-size: 16px;
        }
    }
    .daterangepicker {
        border: none;
        transform: translate(-10%) !important;
        filter: drop-shadow(0 0 5px rgba($black, 0.2));
        &::before {
            top: -6px;
            left: 60px;
            border: none;
            transform: rotate(45deg);
            height: 12px;
            width: 12px;
            margin: 0;
            background-color: white;
        }
        &::after {
            content: none;
        }
    }
    .calendars {
        display: flex;

    }
    .vue-daterange-picker {
        min-width: 100%;
    }
    .daterangepicker {
        top: 50px !important;
    }
    &.builder-variant {
        label {
            display: none;
        }
        .form-control {
            position: relative;
            background: transparent;
            color: $gray-text;
            border: 1px solid $gray-text;
            border-radius: 0;
            padding: 5px 10px;
            &::before {
                position: absolute;
                top: -10px;
                left: 10px;
                padding: 0 5px;
                display: block;
                content: 'Date Range Preview';
                font-size: 11px;
                color: $gray-text;
                background: $gray-darkest;
            }
        }
        .daterangepicker {
            top: 55px !important;
        }
    }
}
</style>